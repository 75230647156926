export default [
    {"name_en": "Algeria", "code": "DZ","name_ar":"الجزائر"},
    {"name_en": "Australia", "code": "AU","name_ar":"أستراليا"},
    {"name_en": "Bahrain", "code": "BH","name_ar":"البحرين"},
    {"name_en": "Djibouti", "code": "DJ","name_ar":"جيبوتي"},
    {"name_en": "Egypt", "code": "EG","name_ar":"مصر"},
    {"name_en": "Indonesia", "code": "ID","name_ar":"أندونيسيا"},
    {"name_en": "Iran", "code": "IR","name_ar":"إيران"},
    {"name_en": "Iraq", "code": "IQ","name_ar":"العراق"},
    {"name_en": "Jordan", "code": "JO","name_ar":"الأردن"},
    {"name_en": "Kuwait", "code": "KW","name_ar":"الكويت"},
    {"name_en": "Lebanon", "code": "LB","name_ar":"لبنان"},
    {"name_en": "Libyan", "code": "LY","name_ar":"ليبيا"},
    {"name_en": "Morocco", "code": "MA","name_ar":"المغرب"},
    {"name_en": "Oman", "code": "OM","name_ar":"عمان"},
    {"name_en": "Pakistan", "code": "PK","name_ar":"باكستان"},
    {"name_en": "Palestinian", "code": "PS","name_ar":"فلسطين"},
    {"name_en": "Qatar", "code": "QA","name_ar":"قطر"},
    {"name_en": "Saudi Arabia", "code": "SA","name_ar":"السعودية"},
    {"name_en": "Somalia", "code": "SO","name_ar":"الصومال"},
    {"name_en": "Sudan", "code": "SD","name_ar":"السودان"},
    {"name_en": "Syrian", "code": "SY","name_ar":"سوريا"},
    {"name_en": "Tunisia", "code": "TN","name_ar":"تونس"},
    {"name_en": "Turkey", "code": "TR","name_ar":"تركيا"},
    {"name_en": "United Arab Emirates", "code": "AE","name_ar":"الإمارات"},
    {"name_en": "United Kingdom", "code": "GB","name_ar":"بريطانيا"},
    {"name_en": "United States", "code": "US","name_ar":"الولايات المتحدة"},
    {"name_en": "Yemen", "code": "YE","name_ar":"اليمن"},
    ]